import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-delete-confirm',
  templateUrl: './delete-confirm.component.html',
  styleUrls: ['./delete-confirm.component.scss']
})
export class DeleteConfirmComponent implements OnInit {

  errorMessage = "";
  errorMessageEnable = false;
  header = "";
  subHeader = ""
  rolesRemoved = [];
  buttonName: string;
  buttonList = [];
  static deleteStatus = false;
  enableB1 = true;
  enableB2 = true;
  b1;
  b2;
  methodMapper = {
    "Ok": "onYesClick",
    "Cancel": "onNoClick",
    "Proceed without Revising": "onProceedClick"
  }
  constructor(public dialogRef: MatDialogRef<DeleteConfirmComponent>,
    @Inject(MAT_DIALOG_DATA) public data) { }

  ngOnInit() {

    this.errorMessage = this.data.errorMessage;
    this.header = this.data.header;
    this.subHeader = this.data.subHeader;
    this.buttonList = this.data.button;
    this.b1 = "No";
    this.b2 = "Ok";
  }
  clickMethod(mName): void {
    this[this.methodMapper[mName]]();
  }
  onYesClick(): void {
    this.dialogRef.close({status:true,msg:"success"});
  }
  onNoClick(): void {
    this.dialogRef.close({status:true,msg:"cancel"});
  }
  onProceedClick(): void {
    this.dialogRef.close({status:false,msg:"cancel"});
  }

}
