import { Component, OnInit } from '@angular/core';
import {FormGroup, FormBuilder, Validators, ValidatorFn, AbstractControl} from '@angular/forms';
import {Router, ActivatedRoute} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {CommonService} from "../Services/Common/common.service";
@Component({
  selector: 'app-resetpassword',
  templateUrl: './resetpassword.component.html',
  styleUrls: ['./resetpassword.component.scss']
})
export class ResetpasswordComponent implements OnInit {


  userData: any = {};
  resetForm: FormGroup;
  resetToken : any = '' ;
  returnUrl : any ='';
  showNavigationArrows = false;


  equalto(field_name): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} => {
      let input = control.value;
      let isValid=control.root.value[field_name]==input
      if(!isValid){
        return { 'equalTo': {isValid} }
      }
      else{
        return null;
      }
    };
  }



  constructor(public userService: CommonService,
              private _formBuilder: FormBuilder,
              private route: ActivatedRoute,
              private router: Router,
              private toastr: ToastrService) {

    this.resetToken = this.route.snapshot.paramMap.get("reset_token");

    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/';

  }

  ngOnInit() {
    window.scrollTo(0, 0);

    // Validation for login form
    this.resetForm = this._formBuilder.group({
      email: ['', [Validators.required,  Validators.pattern('^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$')]],
      password : ['', [Validators.required, Validators.minLength(6), Validators.maxLength(20)]] ,
      password_confirmation : ['',  [Validators.required]]

    });

    this.resetForm.valueChanges.subscribe(field => {
      if (field.password !== field.password_confirmation) {
        //this.password_confirmation.setErrors({ mismatch: true });
        this.resetForm.get('password_confirmation').setErrors({ mismatch: true });
      } else {
        // this.password_confirmation.setErrors(null);
        this.resetForm.get('password_confirmation').setErrors(null);
      }
    });



  }


  doReset(value) {

    // console.log(value);


    value.token = this.resetToken;
    this.userService.resetPassword(value).subscribe((response) => {

      if (response.status== 1) {
        // console.log(response.message);
        this.toastr.success(response.message, 'Success !!!');

        this.router.navigate(['']);

      } else {
        // console.log(response);
        this.toastr.error(response.message, 'Error !!!');
      }

    });


  }

}
