import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router, ActivatedRoute } from "@angular/router";
import { FormBuilder, Validators, FormGroup } from "@angular/forms";
import { CommonService } from "../Services/Common/common.service";
import { ToastrService } from 'ngx-toastr';
import { environment as env } from '../../environments/environment';



@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  encapsulation: ViewEncapsulation.None

})
export class LoginComponent implements OnInit {

  loginForm: FormGroup;
  showNavigationArrows = false;
  checked: any = false;
  images: any = [];
  constructor(public userService: CommonService,
    private _formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private toastr: ToastrService) {

    this.images = [944, 1011, 984].map((n) => `https://picsum.photos/id/${n}/900/500`);

  }

  ngOnInit() {
    this.loginForm = this._formBuilder.group({
      username: ['', [Validators.required, Validators.pattern('^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-z\\-0-9]+\\.)+[a-z]{2,}))$')]],
      password: ['', [Validators.required, Validators.minLength(6), Validators.maxLength(20)]]
    });

    if (localStorage.getItem("remember")) {

      this.loginForm.controls["username"].setValue(localStorage.getItem("remember"));
      this.checked = true;
    }
  }

  rememberMe(data) {
    if (data == true) {
      localStorage.setItem("remember", this.loginForm.value.username);
    }
    else {
      localStorage.setItem("remember", "");
    }
  }

  doLogin(value) {
    value.grant_type = "password";
    value.client_id = env.CLIENT_ID;
    value.client_secret = env.CLIENT_SECRET;
    this.userService.loginSubmit(value).subscribe((response) => {

      if (response.status == false) {
        this.toastr.error(response.message, 'Error !!!');


      } else {
        localStorage.setItem('token', response.access_token);
        this.toastr.success('Login Success!', "Success !!!");

        this.getDepartment();
      }

    }, err => {
      this.toastr.error("Invalid Email or Password", 'Error !!!');
    });
  }

  getDepartment() {

    this.userService.gerDepartment().subscribe((response) => {
      if (response.status) {
        localStorage.setItem("department", response.department);
        if (response.department == "Executive") {
          this.router.navigate(['/dashboard/customer/excutivedashboard']);
        }
        if (response.department == "CSR") {
          this.router.navigate(['/dashboard/customer/home']);
        }
        if (response.department == "Sales") {
          this.router.navigate(['/dashboard/customer/salesdashboard']);
        }
        if (response.department == "Admin") {
          this.router.navigate(['/dashboard/admin/manageuser']);
        }
        if (response.department == "Roaming") {
          this.router.navigate(['/dashboard/customer/transactionhistory']);
        }
        if (response.department == null) {
          this.router.navigate(['/dashboard/admin/manageuser']);
        }

      } else {
        this.toastr.error(response.message, 'Error !!!');
      }

    }, err => {

      this.toastr.error("Email and Password invalid", 'Error !!!');


    });
  }


}
