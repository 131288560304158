import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LoginComponent} from "./login/login.component";
import {ForgetpasswordComponent} from "./forgetpassword/forgetpassword.component";
import {ResetpasswordComponent} from "./resetpassword/resetpassword.component";
import { AddpartnerComponent } from './addpartner/addpartner.component';

const routes: Routes = [
  {path:'',
    redirectTo:'login',
    pathMatch:'full'},
  {path: 'login', component: LoginComponent,data: {title: 'Login'}},
  {path: 'password/forget', component:ForgetpasswordComponent,data: {title: 'forget'}},
  {path: 'password/forget', component:ForgetpasswordComponent,data: {title: 'forget'}},
  {path: 'partner_details/:account_number', component: AddpartnerComponent,data: {title: 'Add Partner'}},
  {path: 'dashboard', loadChildren:'src/app/layout/layout.module#DashboardModule'},


];

@NgModule({
  imports: [RouterModule.forRoot(routes,{useHash: true})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
