import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import {ToastrService} from "ngx-toastr";
import {CommonService} from "../Services/Common/common.service";

@Component({
  selector: 'app-forgetpassword',
  templateUrl: './forgetpassword.component.html',
  styleUrls: ['./forgetpassword.component.scss']
})
export class ForgetpasswordComponent implements OnInit {

  public forgetForm: FormGroup;
  constructor(private fb: FormBuilder, private router: Router, public toastr:ToastrService, public userService:CommonService) {}

  ngOnInit() {
    this.forgetForm = this.fb.group ( {
      email: [null , Validators.compose ( [ Validators.required ,Validators.pattern('^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$')])]
    } );
  }


  doResetEmail(value) {
    this.userService.passwordResetEmail(value).subscribe((response) => {
      if (response.status == 1) {
        // console.log(response.message);
        this.toastr.success(response.message, 'Success !!!');
        this.router.navigate(['/']);

      } else {
        // console.log(response);
        this.toastr.error(response.message, 'Error !!!');
      }

    });


  }

}
