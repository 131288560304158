import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './login/login.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import {MatInputModule,MatDialogModule, MatSelectModule,MatDatepickerModule,MatNativeDateModule,MatCheckboxModule,MAT_DATE_LOCALE
} from '@angular/material';
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { Interceptor } from './interceptor';
import { ToastrModule } from 'ngx-toastr';
import { DataTablesModule } from 'angular-datatables';
import { ForgetpasswordComponent } from './forgetpassword/forgetpassword.component';
import { ResetpasswordComponent } from './resetpassword/resetpassword.component';
import {LoadingBarHttpClientModule} from "@ngx-loading-bar/http-client";
import { AddpartnerComponent } from './addpartner/addpartner.component';
import {MomentDateFormatter} from "./dateformat";

import {NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import { DeleteConfirmComponent } from './common/delete-confirm/delete-confirm.component';

;
import { HighchartsChartModule } from 'highcharts-angular';
import { GaugeModule } from 'angular-gauge';
import { NgxGaugeModule } from 'ngx-gauge';
import { AccumulationChartModule } from '@syncfusion/ej2-angular-charts';





@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    ForgetpasswordComponent,
    ResetpasswordComponent,
    AddpartnerComponent,
    DeleteConfirmComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    NgxGaugeModule,
    GaugeModule.forRoot(),
    MatCheckboxModule,
    NgbModule.forRoot(),
    MatFormFieldModule,
    MatDatepickerModule,
    AccumulationChartModule,
    MatDialogModule,
    MatNativeDateModule,
    MatInputModule,
    HighchartsChartModule,
    MatSelectModule,
    HttpClientModule,
    ToastrModule.forRoot(),
    DataTablesModule,
    LoadingBarHttpClientModule

  ],
  entryComponents : [
    DeleteConfirmComponent
  ],

  providers: [HttpClientModule, { provide: HTTP_INTERCEPTORS, useClass: Interceptor, multi: true },{provide: MAT_DATE_LOCALE, useValue: 'en-in'},{provide: NgbDateParserFormatter, useClass: MomentDateFormatter}],
  bootstrap: [AppComponent]
})
export class AppModule { }
