import {Injectable} from '@angular/core';
import {environment as env} from '../../../environments/environment';
import {HttpClient,HttpHeaders} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CustomerService {
  cust_acc_id = "";
  cust_acc_no = "";
  static custData = {};
  constructor(private _http: HttpClient) { }

  public createCustomer(userData): any {
    const apiURL = env.BASE_URL + 'create-account';
    return this._http.post(apiURL, userData);
  }
  public getPriceBundle(data): any {
    const apiURL = env.BASE_URL + 'get-bundle-details';
    return this._http.post(apiURL,data);
  }

  public getCustomerList(data): any {
    const apiURL = env.BASE_URL + 'list-accounts';
    return this._http.post(apiURL,data);
  }


  public getServices(): any {
    const apiURL = env.BASE_URL + 'search-msisdn-numbers';
    return this._http.get(apiURL);
  }

  public getAnalytics(): any {
    const apiURL = "http://analytics.brt360.local/";
    return this._http.get(apiURL);
  }

  public getAccountOptions(data): any {
    const apiURL = env.BASE_URL+"get-account-options";
    return this._http.post(apiURL,data);
  }

  public validateIdNumber(data): any {
    const apiURL = env.BASE_URL+"validate-ssn";
    return this._http.post(apiURL,data);
  }

  public getConnectionType(): any {
    const apiURL = env.BASE_URL+"get-connection-types";
    return this._http.get(apiURL);
  }

  public getAddOn(data): any {
    const apiURL = env.BASE_URL+"get-addons";
    return this._http.post(apiURL,data);
  }

  public saveService(data): any {
    const apiURL = env.BASE_URL+"save-msisdn-number";
    return this._http.post(apiURL,data);
  }

  public saveBilling(data): any {
    const apiURL = env.BASE_URL+"save-billing-details";
    return this._http.post(apiURL,data);
  }

  public updateOrderStatus(data): any {
    const apiURL = env.BASE_URL+"update-order-status";
    return this._http.post(apiURL,data);
  }

  public savePricing(data): any {
    const apiURL = env.BASE_URL+"save-bundle-for-account";
    return this._http.post(apiURL,data);
  }

  public getDocsList(data): any {
    const apiURL = env.BASE_URL+"list-account-documents";
    return this._http.post(apiURL,data);
  }

  public validateOrder(data): any {
    const apiURL = env.BASE_URL+"validate-order";
    return this._http.post(apiURL,data);
  }

  public submitOrder(data): any {
    const apiURL = env.BASE_URL+"submit-order";
    return this._http.post(apiURL,data);
  }

  public exportPayload(data): any {
    const apiURL = env.BASE_URL+"download-order-payload";
    return this._http.post(apiURL,data);
  }

  public savePayment(data): any {
    const apiURL = env.BASE_URL+"save-payment-details";
    return this._http.post(apiURL,data);
  }

  public createQuote(data): any {
    const apiURL = env.BASE_URL+"create-quote";
    return this._http.post(apiURL,data);
  }

  public saveBundleQuote(data): any {
    const apiURL = env.BASE_URL+"save-bundle-for-quote";
    return this._http.post(apiURL,data);
  }

  public getSearchQuote(data): any {
    const apiURL = env.BASE_URL+"list-quotes";
    return this._http.post(apiURL,data);
  }

  public registerRoaming(data): any {
    const apiURL = env.BASE_URL+"create-roaming-account";
    return this._http.post(apiURL,data);
  }

  public getPartnerList(data): any {
    const apiURL = env.BASE_URL+"roaming-account-lists";
    return this._http.post(apiURL,data);
  }
  public createBlockChain(data): any {
    const apiURL = env.BASE_URL+"create-partner-blockchain";
    return this._http.post(apiURL,data);
  }

  public getTransactionList(data): any {
    const apiURL = env.BASE_URL+"list-transactions";
    return this._http.post(apiURL,data);
  }

  public createTrnsaction(data): any {
    const apiURL = env.BASE_URL+"create-roaming-transaction";
    return this._http.post(apiURL,data);
  }
  public updateSatus(data): any {
   const apiURL = env.BASE_URL+"accept-transactions";
   return this._http.post(apiURL,data);
 }

 public getQuoteData(data): any {
 const apiURL = env.BASE_URL+"account-verification";
 return this._http.post(apiURL,data);
}
 public getQuoteSummery(data): any {
  const apiURL = env.BASE_URL+"quote-summary";
  return this._http.post(apiURL,data);
 }

 public getOrderDetails(data): any {
  const apiURL = env.BASE_URL+"order-details";
  return this._http.post(apiURL,data);
 }

 public saveDeviceInfo(data): any {
  const apiURL = env.BASE_URL+"save-device-informations";
  return this._http.post(apiURL,data);
 }

 public saveDeviceAddress(data): any {
  const apiURL = env.BASE_URL+"save-device-address";
  return this._http.post(apiURL,data);
 }

 public getDeviceInfo(data): any {
  const apiURL = env.BASE_URL+"get-device-informations";
  return this._http.post(apiURL,data);
 }

 public getPaymentHistory(data): any {
  const apiURL = env.BASE_URL+"payment-history";
  return this._http.post(apiURL,data);
 }

 public getRechargeHistory(data): any {
  const apiURL = env.BASE_URL+"recharge-history";
  return this._http.post(apiURL,data);
 }

 public updateDiscount(data): any {
  const apiURL = env.BASE_URL+"update-quote-discounts";
  return this._http.post(apiURL,data);
 }

 public emailQuote(data): any {
  const apiURL = env.BASE_URL+"generate-quote-pdf";
  return this._http.post(apiURL,data);
 }

 public updateStatus(data): any {
  const apiURL = env.BASE_URL+"update-quote-status";
  return this._http.post(apiURL,data);
 }

 public getAccountProfile(data): any {
  const apiURL = env.BASE_URL+"get-account-profile";
  return this._http.post(apiURL,data);
 }
 public getParnerAccountDetail(data): any {
  const apiURL = env.BASE_URL+"get-partner-account-details";
  return this._http.post(apiURL,data);
 }

 public deleteProduct(data): any {
  const apiURL = env.BASE_URL+"delete-bundle-for-quote";
  return this._http.post(apiURL,data);
 }

 public getQuoteHistory(data): any {
  const apiURL = env.BASE_URL+"quote-history";
  return this._http.post(apiURL,data);
 }

 public createOrder(data): any {
  const apiURL = env.BASE_URL+"create-order";
  return this._http.post(apiURL,data);
 }

 public getSearchOrder(data): any {
  const apiURL = env.BASE_URL+"lists-order";
  return this._http.post(apiURL,data);
 }

 public getOrderProfile(data): any {
  const apiURL = env.BASE_URL+"get-order-profile";
  return this._http.post(apiURL,data);
 }

 public getAccountInfo(data): any {
  const apiURL = env.BASE_URL+"get-account-profile";
  return this._http.post(apiURL,data);
 }

 public getMaintenanceDetails(data): any {
  const apiURL = env.BASE_URL+"search-customers";
  return this._http.post(apiURL,data);
 }

 public getPriceBundleBrt(data): any {
  const apiURL = env.BASE_URL + 'get-products';
  return this._http.get(apiURL);
}

public getProductModelDetailsBrt(data): any {
  const apiURL = env.BASE_URL + 'get-product-model-detail';
  return this._http.post(apiURL,data);
}




}
