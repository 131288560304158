// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,

  // Demo
  // BASE_URL: 'http://api.brt360.local/api/',
  //BASE_URL: 'https://api.brt360.io/api/',
 // BASE_URL: 'http://192.168.1.65:8000/api/',
  BASE_URL: 'https://api.brt360.bluerose.solutions/api/',
  //CLIENT_ID:'5',
  CLIENT_ID:'2',
  //CLIENT_SECRET:'xAhJ7J9YRroYLDUFfBNGLXFa3VfkgHAKPL17BNhU',
  CLIENT_SECRET:'iyJ7XesM7VRSjAroWykYJSpPvoLjyDBAFehqzid3',
  APP_NAME: 'BRT 360'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
