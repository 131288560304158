import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { CommonService } from 'src/app/Services/Common/common.service';
import * as moment from 'moment'
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import { CustomerService } from 'src/app/Services/Customer/customer.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-addpartner',
  templateUrl: './addpartner.component.html',
  styleUrls: ['./addpartner.component.scss']
})
export class AddpartnerComponent implements OnInit {

  partnerDetail: FormGroup;
  cityData:any=[];
  countryData:any=[];
  stateData:any=[];
  priceBundle:any=[];
  currentDate= new Date();
  accountNo:any;
  partnerData:any=[];
  savePartnerData:any;


  constructor(public _formBuilder:FormBuilder,public commonService:CommonService, public customerService:CustomerService, public toastr:ToastrService, public modalService:NgbModal, private route: ActivatedRoute,public router:Router) { 
   
   
    this.accountNo = this.route.snapshot.paramMap.get("account_number");
    //this.getAccountDetail({account_no:this.accountNo});
    this.getPartnerAccontDetail({account_no:this.accountNo});


  }

  ngOnInit() {

    this.getCountries();

    this.partnerDetail = this._formBuilder.group({
      vendor_name:['',[Validators.required, Validators.pattern('^[a-zA-Z][a-zA-Z ]*$'), Validators.minLength(3)]],
      email: ['',[Validators.pattern('^(([^<>()\\[\\]\\\\.,;:\\s@"]+(\\.[^<>()\\[\\]\\\\.,;:\\s@"]+)*)|(".+"))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$')]],
      contact_no: ['',[Validators.required, Validators.pattern('^[0-9]{10,15}'), Validators.minLength(10), Validators.maxLength(15)]],
      agreement_start_date:['',Validators.required],
      bundle_id:['',Validators.required],
      plmn_id:['',Validators.required],
      address_line1: ['', [Validators.required, Validators.minLength(3)]],
      address_line2:[''],
      country:['',Validators.required],
      state:['',Validators.required],
      city:['',Validators.required],
      pincode: ['', [Validators.required, Validators.pattern('^[a-zA-Z0-9]{5,10}')]],
      net_code:['',Validators.required],
      is_block_chain_account:['',Validators.required]
    });
  }


  getCountries() {
    this.commonService.getCountry().subscribe((response: any) => {
  
      if (response) {
        this.countryData = response;
  
      }
    });
  
  }
  
  getStateByCountry(country_id: any) {
    let data: any = {};
    data.country_id = country_id;
    this.commonService.getStateByCountry(data).subscribe((response: any) => {
  
      if (response) {
        this.stateData = response;
      }
    });
  
  }
  
  
  
  getCityByState(state_id: any) {
    let data: any = {};
    data.state_id = state_id;
    this.commonService.getCityByState(data).subscribe((response: any) => {
  
      if (response) {
        this.cityData = response;
      }
    });
  
  }

  getAccountDetail(data){

    this.customerService.getPartnerList(data).subscribe((response: any) => {
      if(response.status){
        this.partnerData = response.partners[0];
        this.updateInput();
      }


    });
  }

  getPartnerAccontDetail(data){
    this.customerService.getParnerAccountDetail(data).subscribe((response: any) => {
      if(response.status){
        this.partnerData = response;  
        this.updateInput();
      }


    });
    
  }

  onDateChanged(event) {

    console.log(event.value);

    this.partnerDetail.value.agreement_start_date = moment(event.value).format('DD/MM/YYYY');
    this.partnerDetail.controls["agreement_start_date"].setValue(this.partnerDetail.value.agreement_start_date);
  }

  updateInput(){

    console.log(this.partnerData)
    this.partnerDetail.controls["vendor_name"].setValue(this.partnerData.partner.vendor_name);
    this.partnerDetail.controls["email"].setValue(this.partnerData.partner.email);
    this.partnerDetail.controls["contact_no"].setValue(this.partnerData.partner.contact_no);
    this.partnerDetail.controls["agreement_start_date"].setValue(this.partnerData.partner.agreement_start_date);
    this.partnerDetail.controls["bundle_id"].setValue(this.partnerData.partner.bundle_id);
    this.partnerDetail.controls["plmn_id"].setValue(this.partnerData.partner.plmn_id);
    this.partnerDetail.controls["address_line1"].setValue(this.partnerData.address[0].address_line1);
    this.partnerDetail.controls["address_line2"].setValue(this.partnerData.address[0].address_line2);
    this.getStateByCountry(this.partnerData.address[0].country);
    this.partnerDetail.controls["country"].setValue(this.partnerData.address[0].country);
    this.getCityByState(this.partnerData.address[0].state);
    this.partnerDetail.controls["state"].setValue(this.partnerData.address[0].state);
    this.partnerDetail.controls["city"].setValue(this.partnerData.address[0].city);
    this.partnerDetail.controls["pincode"].setValue(this.partnerData.address[0].pincode);
    this.partnerDetail.controls["net_code"].setValue(this.partnerData.partner.net_code);

  }

  savePartner(content) {
    this.partnerDetail.value.type ="partner"
    this.partnerDetail.value.id= this.partnerData.partner.id;
    this.partnerDetail.value.account_no=this.accountNo;
  
    this.customerService.createBlockChain(this.partnerDetail.value).subscribe((response: any) => {
  
      if (response.status) {

        this.toastr.success(response.message,"Success!!!");
        this.savePartnerData=response.keystore.PrivateKey;
        console.log(response.keystore);
        this.openVerticallyCentered(content);
      }
      else{
        this.toastr.error(response.message,"Error!!!");
        //this.openVerticallyCentered(content);

      }
    }); 
  
  }
  
  openVerticallyCentered(content) {
    const config: NgbModalOptions = {
      backdrop : 'static',
      keyboard : false,
      centered: true ,size: 'sm',
      windowClass : "myCustomModalClass"
    };
    this.modalService.open(content,config);
  }

openLogin(){
  this.modalService.dismissAll();
  this.router.navigate([''],{ replaceUrl: true });
}

downloadKey(){

  console.log(this.savePartnerData);
  const blob = new Blob([this.savePartnerData], {type: 'text/csv'});
  const dataURL = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = dataURL;
  link.download = 'block_chain.txt';
  link.click();

  setTimeout(()=>{
    
    this.openLogin();//<<<---    using ()=> syntax
}, 3000);}
  
}
