import {Injectable} from '@angular/core';
import {environment as env} from '../../../environments/environment';
import {HttpClient,HttpHeaders} from '@angular/common/http';
import { ResponseContentType } from '@angular/http';


@Injectable({
  providedIn: 'root'
})
export class CommonService {

  constructor(private _http: HttpClient) { }

  public loginSubmit(userData): any {
    const apiURL = env.BASE_URL + 'login';
    return this._http.post(apiURL, userData);
  }

  /**
   * This function is to get contries
   * @param data
   * @returns {any}
   */
  public getCountry() : any {
    const apiURL = env.BASE_URL + 'get_countries';
    return this._http.get(apiURL);
  }

  /**
   * This function is to get states by country
   * @param data
   * @returns {any}
   */
  public getStateByCountry(data) : any {
    const apiURL = env.BASE_URL + 'get_states_by_country ';
    return this._http.post(apiURL, data);
  }

  /**
   * This function is to send reset password mail link
   * @param data
   * @returns {any}
   */
  public passwordResetEmail(data) : any {
    const apiURL = env.BASE_URL + 'password/email';
    return this._http.post(apiURL, data);
  }

  /**
   * This function is to reset password
   * @param data
   * @returns {any}
   */
  public resetPassword(data) : any {
    const apiURL = env.BASE_URL + 'password/reset';
    return this._http.post(apiURL, data);
  }

  /**
   * This function is to get states by country
   * @param data
   * @returns {any}
   */
  public getCityByState(data) : any {
    const apiURL = env.BASE_URL + 'get_cities_by_state ';
    return this._http.post(apiURL, data);
  }


  public gerDepartment() : any {
    const apiURL = env.BASE_URL + 'get-roles-permissions';
    return this._http.get(apiURL);
  }


  public downloadFile(url) : any{
    url = "https://api.brt360.bluerose.solutions/uploads/quotes/281.pdf";
    var myParams=  {}
    this._http.post(url,{myParams}, {responseType: 'arraybuffer'})
   .subscribe(function (data) {
       var file = new Blob([data], {type: 'application/pdf'});
       console.log(file)
       var fileURL = URL.createObjectURL(file);
       window.open(fileURL);
});

    // let headers = new HttpHeaders();
    // headers = headers.set('Accept', 'application/pdf');
    // return this._http.post(url, { headers: headers, responseType: 'blob' as 'json' });
  }


}
